@import url('https://fonts.googleapis.com/css?family=Major+Mono+Display|Source+Sans+Pro|Ubuntu|Fira+Code&display=swap');
@import "colors";
@import "sizes";



* {
    border-radius: 0!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bg-a {
  background: rgba(20, 20, 20, 0.5)
}

.bg-b {
  background: rgba(40, 40, 40, 0.4)
}


body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    color: $primary-color;
    font-family: 'Ubuntu', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

}



.applist {
    margin-top: 10px;
    padding-left: 0;
    list-style-type: none;
}

.applist li {
    margin-top: 10px;
}


.content {
    background: rgba(20, 20, 20, 0.5);
    font-size: 1.3em;
    font-family: 'Ubuntu', sans-serif;
    height:100%;
    min-height: calc(100vh - 45px);
    width:100%;
    position: relative;
    overflow: auto;

}

.top-space-for-header {
  margin-top: 45px;
}



.bg-blurred {
    /* The image used */
    /*background-image: url("/img/bg1.jpg");*/

    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.fill * {
    padding: 2px;
}

.faded-blue {
    background-color: #005293;
}

.faded-red {
    background-color: #930004;
}

.faded-green {
    background-color: #119300;
}

.faded-cyan {
    background-color: #00938f;
}

.faded-orange {
    background-color: #935f00;
}

.faded-pink {
    background-color: #930065;
}

.faded-purple {
    background-color: #6a0093;
}

.faded-yellow {
    background-color: #938600;
}


@keyframes moving {
    0% {
        top: 0px;
    }
    50% {
        top: -30px;
    }
    51% {
        top: 30px;
    }
    100% {
        top: 0px;
    }
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    margin: 0.05rem 0;

    margin-bottom: 20px;
    padding: 0;
}

@media only screen and (min-width: $breakwidth) {

}

@media only screen and (max-width: $breakwidth - 1px) {

}

.back-button {
  position: fixed;
  display:inline-block;
  padding: 5px 5px 10px 15px;
  background: rgba(10, 10, 10, 0.6);
  top: 0;
  width: 100%;
  z-index: 4;

}

.back-button-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button-link svg{
  margin-top: -2px;
}


.tile:hover {
    filter: none;
    -webkit-filter: saturate(100%);
    -moz-filter: saturate(100%);
    -o-filter: saturate(100%);
}


a {
    color: $primary-color;
}

.content a:hover {
    color: $primary-color;
}
.background {
  width: 100vw;
  background-color: #856404;
  background: linear-gradient(rgba(82, 126, 160, 0.8), rgba(71, 24, 24, 0.8));

  border-radius: 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white !important;
  height: 100vh;
}
.under-construction-box {
  font-family: 'Major Mono Display', monospace;
  color: black;
  font-size: 30px;

  margin-top: 0.5em;
  margin-left: 0.5em;
  line-height: 1;
}


.fade-in {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


