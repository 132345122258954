.icon-list-item {
  margin-left: 15px;
  margin-right: 15px
}

.img-container {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  transition: left .5s;
}

.home-modal-header {
  padding: 2px;
}

.centered-wsb {
  position: absolute;
  top: 45%;
  left: 50%;

  transform: translate(-50%, -50%);
  transition: left .5s;
}

.home-applications {
  white-space: nowrap;
  padding: 10px 10px 10px 10px;
  //text-transform: lowercase;
  //font-family: 'Major Mono Display', monospace;
  font-family: 'Fira Code', monospace;
  font-size: 2.1em;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;

}

.aicon-bar {
  min-width: 400px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding-bottom: 10px;
}

.centered-btn-wsb {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: left .5s;

}

.justify {
  text-align: justify;
}